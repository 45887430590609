import React from "react";
import { ShopItemList } from "./ShopItemList";
import { ShopContainer } from "./ShopContainer";

export function ShopLandingPage() {
  return (
    <ShopContainer>
      <ShopItemList />
    </ShopContainer>
  );
}
