import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Link,
  Skeleton,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { Wrap, WrapItem } from "@chakra-ui/react";

import { useParams } from "react-router-dom";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";

import { RecipeBadge } from "./recipeBadge";
import { IngredientsOverview } from "./IngredientsOverview";
import { StepTextsOverview } from "./StepTextsOverview";
import { RecipeDetailsCarousel } from "./recipeDetailsCarousel";
import { Helmet } from "react-helmet";
import { getRecipe } from "../../global/contentful/getRecipe";
import { Recipe } from "../../global/types";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";

export function RecipeComponent() {
  let { recipeId } = useParams<{ recipeId: string }>();
  const [recipe, setRecipe] = useState<Recipe>();
  const [portions, setPortions] = useState<number>(2);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    recipeId &&
      getRecipe(recipeId).then((data) => {
        if (data) {
          setRecipe(data);
          setPortions(data?.portions);
          setIsLoading(false);
        }
      });
  }, [recipeId]);

  if (isLoading) {
    return (
      <Stack>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  }

  return (
    <MaxWidthContainer>
      <ContentContainer
        header={
          <Text mb={6}>
            {recipe?.longTitle}
            <Text as={"span"} color={"teal.800"}>
              {" "}
              ({recipe?.nativeTitle})
            </Text>
          </Text>
        }
      >
        <VStack marginTop={-4} space={4}>
          <Helmet>
            <title>
              BOWLANI APP - {recipe?.longTitle} ({recipe?.nativeTitle})
            </title>

            <meta
              property="og:url"
              content={`https://bowlani.app/recipe/${recipe?.sys.id}`}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:title"
              content={`BOWLANI APP - ${recipe?.longTitle} (${recipe?.nativeTitle})`}
            />
            <meta
              property="og:description"
              content={`BOWLANI APP - ${recipe?.longTitle} (${recipe?.nativeTitle})`}
            />
            {recipe?.picturesCollection?.items &&
              recipe?.picturesCollection?.items?.length > 0 && (
                <meta
                  property="og:image"
                  content={recipe?.picturesCollection.items[0].url + "?w=500"}
                />
              )}
          </Helmet>
          {recipe?.picturesCollection?.items &&
            recipe?.picturesCollection?.items?.length > 0 && (
              <RecipeDetailsCarousel
                heroImgUrls={recipe?.picturesCollection.items.map(
                  (picture) => picture.url
                )}
              />
            )}

          <VStack style={{ justifyContent: "left", alignSelf: "flex-start" }}>
            {/* <HStack
            style={{ justifyContent: "left", alignSelf: "flex-start" }}
            shouldWrapChildren={true}
          > */}
            <Wrap>
              {/* {recipe?.category && (
              <WrapItem>
                <RecipeBadge
                  icon="category"
                  text={recipe?.category.titleShort}
                />
              </WrapItem>
            )} */}

              {recipe?.isVegetarian && (
                <WrapItem>
                  <RecipeBadge icon="leaf" text="Vegetarisch" />
                </WrapItem>
              )}
              {recipe?.isVegan && (
                <WrapItem>
                  <RecipeBadge icon="leaf-full" text="Vegan" />
                </WrapItem>
              )}
              {recipe?.preparationTime && (
                <WrapItem>
                  <RecipeBadge
                    icon="prep"
                    text={recipe?.preparationTime + " min. Vorbereitung"}
                  />
                </WrapItem>
              )}
              <WrapItem>
                <RecipeBadge
                  icon="clock"
                  text={recipe?.cookingTime + " min. Kochzeit"}
                />
              </WrapItem>
            </Wrap>

            <HStack style={{ justifyContent: "left", alignSelf: "flex-start" }}>
              <RecipeBadge icon="person" text={portions + " Portionen"} />

              <ButtonGroup variant="outline" isAttached>
                <Button
                  onClick={() => setPortions(portions - 1)}
                  disabled={portions === 1}
                >
                  -
                </Button>

                <Button onClick={() => setPortions(portions + 1)}>+</Button>
              </ButtonGroup>
            </HStack>
          </VStack>

          <VStack space={4} marginTop={4}>
            {recipe?.introduction}

            {recipe && (
              <div>
                <Box mt={10}>
                  <IngredientsOverview
                    recipe={recipe}
                    portions={portions}
                    setPortions={setPortions}
                  />
                </Box>
                <Box mt={10}>
                  <StepTextsOverview recipe={recipe} portions={portions} />
                </Box>

                {/* <Box mt={10}>
                <Text>Guten Appetit!</Text>
              </Box> */}
              </div>
            )}
          </VStack>
        </VStack>
      </ContentContainer>
    </MaxWidthContainer>
  );
}
