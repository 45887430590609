import React, { useContext } from "react";
import {
  VStack,
  Button,
  Box,
  HStack,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { ContentContainer } from "../../generic/layout/contentContainer";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { ShopCartContext } from "./ShopCartContext";
import { FiShoppingCart } from "react-icons/fi";
import { Cart } from "./Cart";

export function ShopContainer({ children }: { children: JSX.Element }) {
  const cartCtx = useContext(ShopCartContext);
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  return (
    <VStack alignItems="flex-end">
      <ContentContainer
        header={
          <HStack justifyContent="space-between">
            <Box>
              <Link to="/shop">Shop</Link>
            </Box>
            {cartCtx.cartItems.length > 0 ? (
              <Box>
                <Button
                  onClick={() => navigate("/shop/cart")}
                  leftIcon={<Icon as={FiShoppingCart} />}
                >
                  Warenkorb ({cartCtx.cartItems.length} Artikel)
                </Button>
              </Box>
            ) : (
              <Box>
                <Button
                  onClick={() => {}}
                  leftIcon={<Icon as={FiShoppingCart} />}
                >
                  Warenkorb (leer)
                </Button>
              </Box>
            )}
          </HStack>
        }
      ></ContentContainer>
      <Box w={"100%"}>{children}</Box>
    </VStack>
  );
}
