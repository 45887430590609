import {
  Text,
  Image,
  HStack,
  Link as LinkShakra,
  useBreakpointValue,
  Stack,
  StackDirection,
} from "@chakra-ui/react";
import logoSdg2 from "../assets/Sustainable_Development_Goal_02ZeroHunger.svg.png";
import { bowlaniColors } from "../../../global/bowlaniColors";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export function CsrZeroHungerBanner() {
  const stackDirection = useBreakpointValue<StackDirection>({
    base: "column",
    md: "row",
  });

  return (
    <Stack
      spacing={4}
      alignItems="start"
      bg={bowlaniColors.yellow[200]}
      p={4}
      rounded={5}
      direction={stackDirection}
    >
      <Image
        src={logoSdg2}
        fit="scale-down"
        style={{ display: "inline-block", height: 120 }}
      />

      <Text fontSize={"larger"}>
        BOWLANI glaubt an eine Welt ohne Hunger und fördert namhafte
        Ernährungsprogramme in Afghanistan („Zero Hunger“). <br />{" "}
        <LinkShakra
          href="https://www.un.org/sustainabledevelopment/hunger/"
          isExternal
          style={{ fontSize: 14 }}
        >
          Weitere Informationen zu den "Sustainable Development Goals" der UN{" "}
          <ExternalLinkIcon mx="2px" />
        </LinkShakra>
      </Text>
    </Stack>
  );
}
