import React from "react";
import {
  Heading,
  VStack,
  Text,
  Button,
  Input,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { stat } from "fs";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";

export function EmailValidatedPage() {
  let { validationId } = useParams<{ validationId: string }>();
  let url =
    "https://prod-10.germanywestcentral.logic.azure.com:443/workflows/bc39d91fa2464597ab08867211894bcf/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RVdJT93MRXKfGqw471VjFangha5BXfkgTOLHp_Qu8-A";
  const { data, status, isSuccess, refetch, error } = useQuery(
    ["emailValidation", validationId],
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: validationId }),
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        console.log("res", res);
        if (res.type == "cors") {
        } else {
          return res.json();
        }
      }
    },
    {
      //refetchOnWindowFocus: false,
      retry: false,

      //enabled: false, // turned off by default, manual refetch is needed
    }
  );
  return (
    <ContentContainer header={<>E-Mail Verifizierung</>}>
      <>
        {status == "error" && (
          <ShowError errorMessage={JSON.stringify(error)} />
        )}
        {status == "success" && <ShowOk />}
        {status == "idle" || (status == "loading" && <ShowLoading />)}
      </>
    </ContentContainer>
  );
}

function ShowLoading() {
  return (
    <>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </>
  );
}

function ShowOk() {
  return (
    <>
      <Alert status="success">
        <AlertIcon />
        Deine Anmeldung war erfolgreich. Willkommen bei BOWLANI.
      </Alert>
    </>
  );
}

function ShowError({ errorMessage }: { errorMessage: string }) {
  return (
    <>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>Hoppla!</AlertTitle>
        <AlertDescription>
          Es gab ein Problem bei der Verifizierung!
        </AlertDescription>
        {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
      </Alert>
    </>
  );
}
