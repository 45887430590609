import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Stack,
  Flex,
  VStack,
  StackDivider,
  Grid,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiBold } from "react-icons/fi";
import { Container } from "../../generic/layout/container";

import hunger from "./hunger.png";

import wfpPic from "./UNWFPlogoregularwhite.png";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { Link } from "react-router-dom";
import { CsrHeader } from "../csr/components/CsrHeader";
import { CsrZeroHungerBanner } from "../csr/components/CsrZeroHungerBanner";
import { CsrSaveTheChIntroText } from "../csr/components/CsrSaveTheChIntroText";

export default function SocialResponsibilityComponent() {
  return (
    <MaxWidthContainer>
      <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <CsrHeader />

        <VStack spacing={8} align={"center"}>
          <CsrZeroHungerBanner />
          <CsrSaveTheChIntroText />
          <Link to={"/csr"}>
            <Button>Erfahre mehr</Button>
          </Link>
        </VStack>
      </Stack>
    </MaxWidthContainer>
  );
}
