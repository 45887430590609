import { Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";

export function AboutUs() {
  return (
    <ContentContainer header={<>Über uns</>}>
      <VStack spacing={5}>
        <Text fontSize={"larger"}>
          Wir sind ein junges Food-Startup aus Hamburg. Als Familienunternehmen
          mit afghanischen Wurzeln möchten wir zeigen wie vielseitig und lecker
          die afghanische Küche ist.
        </Text>
        <Text fontSize={"larger"}>
          Dafür haben wir nicht nur die erste Cooking-App mit ausgewählten
          Rezepten der afghanischen Küche entwickelt, sondern sogar unsere
          eigenen Gewürze kreiert um Euch das volle BOWLANI Erlebnis zu bieten.
        </Text>
        <Text fontSize={"larger"}>
          Für unsere einzigartige, hauseigene Rezeptur haben wir uns mit den
          besten Gewürzherstellern im Norden Deutschlands zusammengesetzt und
          die BOWLANI SPICES No. 1 (Gewürzmischung für Reis), No. 3
          (Gewürzmischung für Schmorgerichte) und No. 5 (Gewürzmischung für
          Gemüse und Fleisch) kreiert.
        </Text>
        <Text fontSize={"larger"}>
          Unsere feinen Kompositionen mit natürlichen Zutaten enthalten keine
          Weichmacher oder zusätzliche Aromen. Wir haben uns entschieden, dabei
          komplett auf Geschmacksverstärker und Konservierungsstoffe zu
          verzichten.
        </Text>
        <Text fontSize={"larger"}>
          Unsere Gewürze werden hier in Deutschland produziert und sind unter
          anderem in unserem Onlineshop erhältlich.
        </Text>
        <Text fontSize={"larger"}>
          Als wir BOWLANI gegründet haben, wussten wir schnell, dass es nicht
          nur ums Essen oder die einfache Zubereitung authentischer Gerichte
          geht. BOWLANI ist mehr als ein Kochbuch mit kulinarischen Rezepten
          afghanischer Küche zum Ausprobieren.
        </Text>
        <Text fontSize={"larger"}>
          Bei BOWLANI geht es um eine Philosophie, um ein Lebensgefühl; es geht
          um die Liebe zum guten Essen, um die Freude eine Mahlzeit zusammen
          zuzubereiten und zu genießen.
        </Text>
        <Text fontSize={"larger"}>
          Mit BOWLANI möchten wir das positive Lebensgefühl der afghanischen
          Küche vermitteln. Wir möchten zeigen, dass die Küche der Afghanen ist
          wie die Afghanen selbst: Herzlich, freundlich und liebevoll. Der Gast
          steht dabei immer im Mittelpunkt.
        </Text>
        <Text fontSize={"larger"}>
          Wir bei BOWLANI möchten eine auf das Wesentliche reduzierte,
          authentische Küche vermitteln und zeigen, dass traditionelles Essen
          modern sein kann, ohne durchgestylt zu sein oder an Authentizität zu
          verlieren.  
        </Text>
      </VStack>
    </ContentContainer>
  );
}
