import {
  Button,
  Checkbox,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { ContentContainer } from "./layout/contentContainer";

export function CookieConsentComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: localStorage.getItem("useAnalytics") == undefined,
  });

  function close(accept: boolean) {
    localStorage.setItem("useAnalytics", accept ? "1" : "0");
    window.location.href = window.location.href;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Stack
            flex={1}
            spacing={{ base: 5, md: 10 }}
            py={{ base: 5, md: 10 }}
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "xl", sm: "2xl", lg: "3xl" }}
            >
              <Text as={"span"} position={"relative"}>
                Wir verwenden Cookies
              </Text>
            </Heading>
            <Text>
              <VStack spacing="5" align="flex-start">
                <p>
                  Wir verwenden Cookies, um Ihnen einen optimalen Service zu
                  bieten und auf Basis von Analysen unsere Webseiten weiter zu
                  verbessern.
                </p>

                <p>
                  Bitte beachten Sie, dass technisch notwendige Cookies gesetzt
                  werden müssen, um den Betrieb der Website sicherstellen zu
                  können.
                </p>

                <Text fontSize="xl">Analyse Cookies</Text>
                <p>
                  <HStack alignItems="start" spacing="5">
                    {/* <Checkbox checked={false} mt={1}></Checkbox> */}
                    <Text>
                      Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck,
                      die Qualität unserer Website und ihre Inhalte zu
                      verbessern. Durch die Analyse-Cookies erfahren wir, wie
                      die Webseite genutzt wird und können so unser Angebot
                      stetig optimieren.
                    </Text>
                  </HStack>
                </p>
              </VStack>
            </Text>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack alignItems="start" spacing="5">
            <Button colorScheme="gray" onClick={() => close(false)}>
              Ablehnen
            </Button>
            <Button
              bg={useColorModeValue("teal.800", "teal.800")}
              color={useColorModeValue("white", "gray.800")}
              _hover={{
                bg: "teal.600",
              }}
              onClick={() => close(true)}
            >
              Akzeptieren
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
