import { Heading } from "@chakra-ui/react";
import React from "react";

export function HeadingMedium({ children }: { children: string | undefined }) {
  return (
    <Heading
      mb={6}
      fontSize={{ base: "xl", sm: "2xl", lg: "3xl" }}
      alignSelf="flex-start"
      color="blackAlpha.800"
    >
      {children}
    </Heading>
  );
}
