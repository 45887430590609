import { ReactElement } from "react";
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from "@chakra-ui/react";
import { FiBold, FiFeather } from "react-icons/fi";

import { Container } from "../../generic/layout/container";
import { motion } from "framer-motion";
import {
  AiOutlineFlag,
  AiOutlineShoppingCart,
  AiOutlineSmile,
} from "react-icons/ai";
import { BiDonateHeart } from "react-icons/bi";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { bowlaniColors } from "../../global/bowlaniColors";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  const variants = {
    animate: {
      y: [-2, 5],
      transition: {
        y: {
          yoyo: Infinity,
          duration: 3,
          delay: Math.random(),
          ease: "easeOut",
        },
      },
    },
  };
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        <motion.div variants={variants} animate="animate">
          {icon}
        </motion.div>
      </Flex>

      <Text
        fontWeight={600}
        fontSize={"larger"}
        color={bowlaniColors.teal[1000]}
      >
        {title}
      </Text>
      <Text color={"gray.800"}>{text}</Text>
    </Stack>
  );
};

export default function FeaturesComponent() {
  return (
    <Box bg={bowlaniColors.teal[200]} width="100%">
      <MaxWidthContainer>
        <Box my={12}>
          <SimpleGrid columns={{ base: 2, sm: 2, md: 4 }} spacing={10}>
            <Feature
              icon={
                <Icon
                  as={AiOutlineFlag}
                  w={10}
                  h={10}
                  color={bowlaniColors.yellow[800]}
                />
              }
              title={"Authentische Küche"}
              text={
                "BOWLANI ist ein digitales Kochbuch mit Originalrezepten der afghanischen Küche."
              }
            />
            <Feature
              icon={
                <Icon
                  as={AiOutlineSmile}
                  w={10}
                  h={10}
                  color={bowlaniColors.yellow[800]}
                />
              }
              title={"Einfach nachzukochen"}
              text={
                "BOWLANI bietet authentische Rezepte mit einfachen Anleitungen."
              }
            />
            <Feature
              icon={
                <Icon
                  as={AiOutlineShoppingCart}
                  w={10}
                  h={10}
                  color={bowlaniColors.yellow[800]}
                />
              }
              title={"Lokal erhältlich"}
              text={
                "Bei BOWLANI kochst Du mit Zutaten aus dem örtlichen Supermarkt."
              }
            />
            <Feature
              icon={
                <Icon
                  as={BiDonateHeart}
                  w={10}
                  h={10}
                  color={bowlaniColors.yellow[800]}
                />
              }
              title={"Vegetarisch & vegan"}
              text={
                "Bei BOWLANI gibt es viele vegetarische und vegane Alternativen."
              }
            />
          </SimpleGrid>
        </Box>
      </MaxWidthContainer>
    </Box>
  );
}
