import React from "react";
import {
  Box,
  Container as ChakraContainer,
  ContainerProps,
} from "@chakra-ui/react";

export function MaxWidthContainer({ children, ...rest }: ContainerProps) {
  return (
    <ChakraContainer {...rest} px={10} maxW="3xl">
      {children}
    </ChakraContainer>
  );
}
