import { Stack, Image, VStack } from "@chakra-ui/react";
import AppStoreBadge from "./appStoreBadge.png";
import GooglePlayBadge from "./googlePlayBadge.png";

export const AppStoreUrl = "https://apple.co/3zRKvlH";
export const PlayStoreUrl =
  "https://play.google.com/store/apps/details?id=app.bowlani&gl=DE";

export function StoreBadges() {
  return (
    <VStack spacing={6}>
      <VStack spacing={4}>
        <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: "row" }}>
          <a href={AppStoreUrl}>
            <Image src={AppStoreBadge} fit="cover" maxW={["32", "40"]} />
          </a>

          <a href={PlayStoreUrl}>
            <Image src={GooglePlayBadge} fit="cover" maxW={["32", "40"]} />
          </a>
        </Stack>
      </VStack>
    </VStack>
  );
}
