import {
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconButton,
  createIcon,
  IconProps,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { MaxWidthContainer } from "./maxWidthContainer";

export function ContentContainer({
  header,
  children,
}: {
  header: JSX.Element;
  children?: JSX.Element | undefined;
}) {
  return (
    <MaxWidthContainer>
      <Stack flex={1} spacing={{ base: 5, md: 10 }} py={{ base: 5, md: 10 }}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
        >
          <Text as={"span"} position={"relative"}>
            {header}
          </Text>
        </Heading>
        {children && <VStack align="flex-start">{children}</VStack>}
      </Stack>
    </MaxWidthContainer>
  );
}
