import React from "react";
import { Heading, VStack, Text, Button, Input, Box } from "@chakra-ui/react";
import { SignUpComponent } from "./signUp";

import FeaturesComponent from "./features";
import { PreviewPicturesComponent } from "./previewPictures";
import SocialResponsibilityComponent from "./socialResponsibility";
import HeroPlate from "./heroPlate";
import { AppPictures } from "./appPictures";
import { ShopLink } from "./shopLink";
import HeroPlate2 from "./heroPlate2";
import { HeroComponent } from "./hero";

export function LandingPage() {
  return (
    <VStack
      //divider={<StackDivider borderColor="gray.200" />}
      spacing={0}
      pb={50}
      pt={50}
    >
      <HeroComponent />
      {/*  <Box h={"20px"} />
      <HeroPlate /> */}
      <FeaturesComponent />
      <ShopLink />
      <Box h={10} />
      <SocialResponsibilityComponent />
      <Box h={10} />
      <HeroPlate2 />

      <SignUpComponent />
      <Box h={10} />
      <PreviewPicturesComponent />
    </VStack>
  );
}
