import { extendTheme } from "@chakra-ui/react";
import { bowlaniColors } from "../global/bowlaniColors";
// 2. Call `extendTheme` and pass your custom values

export const theme = extendTheme({
  colors: {
    teal: {
      100: bowlaniColors.teal[200],
      200: bowlaniColors.teal[200],
      400: bowlaniColors.teal[400],
      500: bowlaniColors.teal[400],
      600: bowlaniColors.teal[600],
      800: bowlaniColors.teal[800],
      1000: bowlaniColors.teal[1000],
    },
    blue: {
      200: "#c8d6e1",
      400: "#b2d1eb",
      600: "#7ab0dc",
      800: "#51799b",
      1000: "#24455d",
    },
    gray: {
      200: "#d6d6d6",
      400: "#c2c2c2",
      600: "#999999",
      800: "#656566",
      1000: "#1d1d1b",
    },
    yellow: {
      200: "#f8e2c3",
      400: "#f2ca91",
      600: "#eeb864",
      800: "#d19630",
      1000: "#765718",
    },
    red: {
      200: "#f5e1e0",
      400: "#e8b7b5",
      600: "#d8827f",
      800: "#ba5854",
      1000: "#69322f",
    },
  },
});
