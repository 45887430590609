import React, { useEffect, useState } from "react";
import { Box, VStack, Image } from "@chakra-ui/react";
import { useParams } from "react-router";
import { ShopItem } from "./types";
import { getShopItem } from "../../global/contentful/getShopItem";
import { HeadingMedium } from "../../generic/headingMedium";

import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { bowlaniColors } from "../../global/bowlaniColors";
import { ShopContainer } from "./ShopContainer";
import { AddToCart } from "./AddToCart";

export function ShopItemDetails() {
  const { shopItemId } = useParams();
  const [shopItem, setShopItem] = useState<ShopItem>();

  useEffect(() => {
    if (shopItemId) {
      getShopItem(shopItemId).then((shopItem) => setShopItem(shopItem));
    }
  }, [shopItemId]);

  return (
    <ShopContainer>
      <Box bg={bowlaniColors.teal[200]} width="100%" py={8}>
        <MaxWidthContainer>
          {shopItem && (
            <VStack spacing={4} alignItems="flex-start">
              <Box
                fontSize={{ base: "1xl", sm: "2xl", lg: "2xl" }}
                fontWeight={600}
              >
                {shopItem?.displayName}
              </Box>
              <Box>{shopItem?.subtitle}</Box>
              <Box boxSize="100%">
                <Image src={shopItem?.thumbnail?.url} alt="Thumbnail" />
              </Box>
              <Box>{shopItem?.description}</Box>
              <Box>Zutaten: {shopItem?.ingredients}</Box>
              <Box>{shopItem?.priceEuro} €</Box>
              {/* <Box>{JSON.stringify(shopItem?.nutritionTable)}</Box> */}
              <AddToCart shopItem={shopItem} variant="regular" />
            </VStack>
          )}
        </MaxWidthContainer>
      </Box>
    </ShopContainer>
  );
}
