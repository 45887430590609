import { Button } from "@chakra-ui/button";
import { Input } from "@chakra-ui/input";
import { Box, Heading, HStack, VStack, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";

import {
  CalculatedIngredient,
  IngredientOverviewItem,
} from "./IngredientOverviewItem";
import { groupIngredients } from "./groupIngredients";
import { HeadingMedium } from "../../generic/headingMedium";
import { BsArrowLeftRight } from "react-icons/bs";
import { Ingredient, Recipe } from "../../global/types";
import { extractIngredientsFromRecipeSteps } from "./extractIngredientsFromRecipeSteps";

export function IngredientsOverview({
  recipe,
  portions,
  setPortions,
}: {
  recipe: Recipe;
  portions: number;
  setPortions: (portions: number) => void;
}) {
  const [list, setList] = useState<Ingredient[]>();
  /* const [portions, setPortions] = useState<number>(2); */
  useEffect(() => {
    setList(groupIngredients(extractIngredientsFromRecipeSteps(recipe)));
  }, []);

  return (
    <Box>
      <HeadingMedium>Zutaten</HeadingMedium>
      <VStack space={2} alignItems="flex-start">
        {list
          ?.filter((x) => x.amount > 0)
          ?.filter((x) => x.food.showInIngredientOverviewList)
          ?.map((ingredient) => (
            <IngredientOverviewItem
              ingredient={ingredient}
              key={ingredient.sys.id}
              defaultPortions={recipe.portions}
              portions={portions}
            />
          ))}
        {recipe.stepsCollection.items
          ?.filter((x) => x.ingredientAlternative !== undefined)
          ?.map(
            (step) =>
              step.ingredientAlternative && (
                <Box>
                  <CalculatedIngredient
                    ingredient={step.ingredientAlternative}
                    portions={portions}
                    defaultPortions={recipe.portions}
                  />
                  <Box
                    ml="1"
                    pl="2"
                    borderLeftColor="gray.300"
                    borderLeftWidth="2px"
                  >
                    <HStack>
                      <BsArrowLeftRight
                        color="blackAlpha.600"
                        style={{
                          display: "inline",
                          width: 12,
                          height: 12,
                        }}
                      />
                      <Text>alternativ:</Text>
                    </HStack>

                    {step.ingredientsCollection.items.map((ingredient) => (
                      <IngredientOverviewItem
                        ingredient={ingredient}
                        key={ingredient.sys.id}
                        defaultPortions={recipe.portions}
                        portions={portions}
                      />
                    ))}
                  </Box>
                </Box>
              )
          )}
      </VStack>
    </Box>
  );
}
