import React from "react";
import { Flex } from "@chakra-ui/react";

import { Container } from "./container";
import { Footer } from "./footer";
import { Header } from "./header";
import { motion } from "framer-motion";

export function Layout({ children }: { children: JSX.Element }) {
  const variants = {
    initial: { opacity: 0, y: "0.5vh" },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 2,
        delay: 0.5,
        ease: "easeOut",
      },
    },
  };
  return (
    <Flex
      direction="column"
      minHeight="100vh"
      backgroundColor="var(--app-background-color)"
    >
      <Header />
      <motion.div variants={variants} initial="initial" animate="animate">
        <Container as="main" p={0}>
          {children}
        </Container>

        <Footer />
      </motion.div>
    </Flex>
  );
}
