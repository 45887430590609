import React, { useContext } from "react";
import { VStack, Button, Box, HStack, Image, Icon } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { ShopItem } from "./types";
import { Link } from "react-router-dom";
import { ShopCartContext } from "./ShopCartContext";
import { FiShoppingCart } from "react-icons/fi";
import { bowlaniColors } from "../../global/bowlaniColors";
import { AddToCart } from "./AddToCart";

export function ShopItemListElement({ shopItem }: { shopItem: ShopItem }) {
  const cartCtx = useContext(ShopCartContext);
  return (
    <HStack justifyContent="space-between" w={"100%"}>
      <HStack>
        <Link to={"item/" + shopItem.sys.id}>
          <Image
            boxSize="75px"
            objectFit="cover"
            rounded={4}
            src={shopItem.thumbnail?.url}
            alt={shopItem.displayName}
          />{" "}
        </Link>
        <VStack alignItems="flex-start" spacing={0}>
          <Link to={"item/" + shopItem.sys.id}>
            <strong>{shopItem.displayName}</strong>
          </Link>
          <Link to={"item/" + shopItem.sys.id}>{shopItem.priceEuro} €</Link>
          <Box display={{ base: "none", sm: "block" }}>
            <Link to={"item/" + shopItem.sys.id}>
              <Box color={bowlaniColors.gray[600]} fontSize={14}>
                {shopItem.subtitle}
              </Box>
            </Link>
          </Box>
        </VStack>
      </HStack>

      <AddToCart shopItem={shopItem} variant="small" />
    </HStack>
  );
}
