import { Box, Image } from "@chakra-ui/react";
import { bowlaniColors } from "../../global/bowlaniColors";

import heroPlate1 from "./heroPictures/hero-plate2.jpg";

export default function HeroPlate2() {
  //const appImg = useBreakpointValue({ base: appImg1, md: appImg1 });

  return (
    <Box
      position={"relative"}
      width={"full"}
      overflow={"hidden"}
      bg={bowlaniColors.teal[200]}
    >
      <Image
        alt={"BOWLANI Plate"}
        fit={"contain"}
        align={"center"}
        src={heroPlate1}
        w={"100%"}
        ml="auto"
        mr="auto"
      />
    </Box>
  );
}
