import {
  Button,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { useContext } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { formatPrice } from "./PriceTag";
import { ShopCartContext } from "./ShopCartContext";

type OrderSummaryItemProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

const OrderSummaryItem = (props: OrderSummaryItemProps) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode("gray.600", "gray.400")}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};

export const CartOrderSummary = () => {
  const cartCtx = useContext(ShopCartContext);
  const shippingCost = 4.4;
  const subTotal = cartCtx.getSubtotal();
  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full">
      <Heading size="md">Bestellung</Heading>

      <Stack spacing="6">
        <OrderSummaryItem label="Summe" value={formatPrice(subTotal)} />

        <OrderSummaryItem
          label="Lieferkosten"
          value={formatPrice(shippingCost)}
        />

        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Total
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(subTotal + shippingCost)}
          </Text>
        </Flex>
      </Stack>
      {/*       <Button
        colorScheme="teal"
        size="lg"
        fontSize="md"
        rightIcon={<FaArrowRight />}
      >
        Bestellen
      </Button> */}
      <Button
        colorScheme="yellow"
        onClick={() => cartCtx.checkout.mutate(cartCtx.cartItems)}
        isLoading={cartCtx.checkout.isLoading}
        leftIcon={<Icon as={FiTruck} />}
        justifySelf="flex-end"
        mt={4}
      >
        Bestellen
      </Button>
    </Stack>
  );
};
