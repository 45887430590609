import React from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Heading,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";

import { BiMailSend } from "react-icons/bi";
import { useQuery } from "react-query";
import { Container } from "../../generic/layout/container";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { bowlaniColors } from "../../global/bowlaniColors";

export function SignUpComponent() {
  const [value, setValue] = React.useState("");
  const handleChange = (event: any) => setValue(event.target.value);
  let url =
    "https://prod-22.germanywestcentral.logic.azure.com:443/workflows/fc0ddc5a2a544c548518f75a4bf793a0/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OOXEq-PH6d3ELHUPst8QiDr0uB0SNq4jpr7YbylwmRE";
  const { data, status, isSuccess, refetch, error } = useQuery(
    "registerEmail",
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: value }),
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(JSON.stringify(res));
      } else {
        if (res.type == "cors") {
        } else {
          return res.json();
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  return (
    <Box bg={bowlaniColors.teal[200]} width="100%">
      <MaxWidthContainer>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "1fr 1fr" }}
          spacing={{ base: 5, md: 10 }}
          my={10}
        >
          <Stack direction={"column"}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
            >
              <HStack>
                <div>
                  <Text as={"span"} color={"teal.800"}>
                    Be the
                  </Text>{" "}
                  <Text as={"span"}>first!</Text>
                </div>
              </HStack>
            </Heading>
            <Text color={"gray.500"} fontSize={"larger"}>
              Registriere Dich noch heute für Neuigkeiten zur BOWLANI App und
              den BOWLANI Spices!
            </Text>
          </Stack>
          <Stack direction={"column"}>
            <Stack direction={"row"}>
              <Input
                placeholder={"Deine E-Mail Adresse"}
                bg={"white"}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
                onChange={handleChange}
                value={value}
              />
              <IconButton
                bg={useColorModeValue("teal.800", "teal.800")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "teal.600",
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
                onClick={() => refetch()}
                disabled={status == "success"}
                isLoading={status == "loading"}
              />
            </Stack>
            {status == "success" && (
              <Alert
                status="success"
                backgroundColor={bowlaniColors.yellow[200]}
              >
                Super! Wir haben Deine E-Mail Adresse erfasst. In Deinem
                Posteingang wartet eine Nachricht von BOWLANI. <br />
                <br />
                Verifiziere jetzt Deine E-Mail Adresse.
              </Alert>
            )}
            {status == "error" && (
              <Alert status="error">
                <AlertDescription>
                  Es gibt ein Problem mit Deiner E-Mail Adresse! Bitte versuche
                  es erneut.
                </AlertDescription>
              </Alert>
            )}
            <Text color={"gray.500"} fontSize={"small"}>
              Mit der Eintragung Deiner E-Mail Adresse erklärst Du Dich mit den
              Datenschutzrichtlinien und Nutzungsbedingungen von BOWLANI
              einverstanden. Du kannst Dich jederzeit auch wieder abmelden.
              Schreibe uns dazu bitte eine E-Mail an hi@bowlani.app.
            </Text>
          </Stack>
        </SimpleGrid>
      </MaxWidthContainer>
    </Box>
  );
}
