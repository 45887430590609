import React, { useEffect, useState } from "react";
import { VStack } from "@chakra-ui/react";
import { ShopItem } from "./types";
import { getShopItems } from "../../global/contentful/getShopItems";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { ShopItemListElement } from "./ShopItemListElement";
import { Cart } from "./Cart";

export function ShopItemList() {
  const [shopItemList, setShopItemList] = useState<ShopItem[]>();
  useEffect(() => {
    getShopItems().then((shopItems) => setShopItemList(shopItems));
  }, []);
  return (
    <>
      {/* <Cart /> */}
      <MaxWidthContainer>
        <VStack spacing={4} alignItems="flex-start">
          {shopItemList?.map((shopItem) => (
            <ShopItemListElement shopItem={shopItem} key={shopItem.sys.id} />
          ))}
        </VStack>
      </MaxWidthContainer>
    </>
  );
}
