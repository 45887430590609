import React from "react";
import { Container as ChakraContainer, ContainerProps } from "@chakra-ui/react";

export function Container({ children, ...rest }: ContainerProps) {
  return (
    <ChakraContainer {...rest} maxWidth="100%">
      {children}
    </ChakraContainer>
  );
}
