import { ShopItem } from "../../pages/shop/types";
import { performContentfulQuery } from "./performContentfulQuery";

const query = `
  {
    shopItemCollection(
      order: displayName_ASC,
    ) {
      items {
        sys {
          id
        }
        displayName
        thumbnail {
          title
          url
          width
          height   
        }
        description
        priceEuro
        stripePriceId
        stripePriceIdTest
        subtitle
      }
    }
  }
  `;

export async function getShopItems(): Promise<ShopItem[] | undefined> {
  const data = await performContentfulQuery<{
    shopItemCollection: { items: ShopItem[] };
  }>(query);

  return data?.shopItemCollection.items;
}
