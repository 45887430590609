import { useContext, useEffect } from "react";
import {
  Box,
  HStack,
  Collapse,
  Flex,
  Heading,
  Link,
  Stack,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { ShopCartContext } from "./ShopCartContext";
import { CartItem } from "./CartItem";
import { CartOrderSummary } from "./CartOrderSummary";
import { bowlaniColors } from "../../global/bowlaniColors";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { ShopContainer } from "./ShopContainer";
import { Navigate, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export function Checkout() {
  const cartCtx = useContext(ShopCartContext);
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();

  useEffect(() => {
    if (cartCtx.checkout.status == "success") {
      document.location.href = cartCtx.checkout.data?.data.sessionUrl;
    }
  }, [cartCtx.checkout.status]);

  return (
    <ShopContainer>
      <MaxWidthContainer>
        <Stack
          direction={{ base: "column", lg: "row" }}
          align={{ lg: "flex-start" }}
          spacing={{ base: "8", md: "16" }}
        >
          <Stack spacing={{ base: "8", md: "10" }} flex="2">
            <Stack spacing="6">
              {cartCtx.cartItems.map((item) => (
                <CartItem key={item.priceId} cartItem={item} />
              ))}
            </Stack>
          </Stack>
          {cartCtx.cartItems.length > 0 && (
            <Flex direction="column" align="center" flex="1">
              <CartOrderSummary />
              <HStack mt="6" fontWeight="semibold" alignItems="flex-start">
                <p>oder</p>
                <Link
                  color={bowlaniColors.teal[800]}
                  onClick={() => navigate("/shop")}
                >
                  weiter shoppen
                </Link>
              </HStack>
            </Flex>
          )}
        </Stack>
        {cartCtx.cartItems.length == 0 && (
          <Alert
            status="info"
            colorScheme="teal"
            borderRadius={4}
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px"
          >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
              Warenkorb ist leer
            </AlertTitle>
            <AlertDescription maxWidth="sm">
              Scheinbar hast du noch nichts in den Warenkorb gelegt. Schaue{" "}
              <Link onClick={() => navigate("/shop")}>hier</Link> unsere Artikel
              an.
            </AlertDescription>
          </Alert>
        )}
      </MaxWidthContainer>
    </ShopContainer>
  );
}
