import { useEffect, useState } from "react";
import {
  AppStoreUrl,
  PlayStoreUrl,
} from "../../generic/storeBadges/StoreBadges";
import { getUserOs } from "../../helper/getUserOs";
import { HeroComponent } from "../landing/hero";

export function Download() {
  useEffect(() => {
    // Update the document title using the browser API
    const userOs = getUserOs();

    if (userOs == "iPhone" || userOs == "iPad") {
      document.location.href = AppStoreUrl;
    }

    if (userOs == "Android" || userOs == "Linux") {
      document.location.href = PlayStoreUrl;
    }
  }, []);

  return <HeroComponent />;
}
