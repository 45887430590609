import { Link, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";
import { BowlaniAdress } from "../../helper/BowlaniAdress";

export function ImprintComponent() {
  return (
    <ContentContainer header={<>Impressum</>}>
      <>
        <BowlaniAdress />
        <p>
          Handelsregister: HRB 175458 <br />
          Registergericht: Amtsgericht Hamburg <br />
          USt-IdNr: DE354576450 <br />
          Steuer Nr.: 46/708/04254 <br />
          Geschäftsführung: Dr. W. Mangal
        </p>
        <p>
          <strong>Streitschlichtung:</strong> Die Europäische Kommission stellt
          eine Plattform zur Online-Streitbeilegung bereit:&nbsp;
          <Link color="teal.800" href="https://ec.europa.eu/consumers/odr">
            https://ec.europa.eu/consumers/odr
          </Link>
          .
        </p>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          <strong>Haftung für Inhalte: </strong> Als Dienstanbieter sind wir im
          Sinne des § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
          allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
          Dienstanbieter jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        </p>
        <p>
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werden wir diese Inhalte
          umgehend entfernen.
        </p>
        <p>
          <strong>Haftung für Links:</strong> Unser Angebot enthält Links zu
          externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
          haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr
          übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
          jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
          verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
          Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
          der Verlinkung nicht erkennbar.
        </p>
        <p>
          Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <p>
          <strong>Urheberrecht:</strong> Die durch die Seitenbetreiber
          erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
          deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung
          und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
          bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
          Erstellers. Downloads und Kopien dieser Seite sind nur für den
          privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
          auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
          Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
          als solche gekennzeichnet. Sollten Sie trotzdem auf eine
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
      </>
    </ContentContainer>
  );
}
