import {
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Tag,
  VStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { ChakraCarousel } from "../../generic/chakraCaroussel";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";

import thumbnail1 from "./appPictures/Thumbnail1.jpg";
import thumbnail2 from "./appPictures/Thumbnail2.jpg";
import thumbnail3 from "./appPictures/Thumbnail3.jpg";
import thumbnail4 from "./appPictures/Thumbnail4.jpg";
import thumbnail5 from "./appPictures/Thumbnail5.jpg";
import thumbnail6 from "./appPictures/Thumbnail6.jpg";
import thumbnail7 from "./appPictures/Thumbnail7.jpg";

type pics = {
  image: string;
};

export function AppPictures() {
  const [data, setData] = useState<pics[]>([
    { image: thumbnail1 },
    { image: thumbnail2 },
    { image: thumbnail3 },
    { image: thumbnail4 },
    { image: thumbnail5 },
    { image: thumbnail6 },
    { image: thumbnail7 },
  ]);

  return (
    <Container
      py={8}
      px={0}
      maxW={{
        base: "100%",
        sm: "35rem",
        md: "43.75rem",
        lg: "57.5rem",
        xl: "75rem",
        xxl: "87.5rem",
      }}
    >
      <ChakraCarousel gap={12}>
        {data.map((post, index) => (
          <Flex
            key={index}
            justifyContent="space-between"
            flexDirection="column"
            overflow="hidden"
            color="gray.300"
            bg="base.d100"
            rounded={5}
            flex={1}
          >
            <Image src={post.image} fit="cover" />
          </Flex>
        ))}
      </ChakraCarousel>
    </Container>
  );
}
