import { ShopItem } from "../../pages/shop/types";
import { Recipe } from "../types";
import { performContentfulQuery } from "./performContentfulQuery";

const getShopItemQuery = (shopItemId: string) => {
  return `
{
  shopItem(id: "${shopItemId}") {
    sys {
      id
    }
    displayName
    subtitle
    thumbnail {
      title
      url
      width
      height   
    }
    description
    priceEuro
    ingredients
    stripePriceId
    nutritionTable {
      json
    }
    imagesCollection {
      items {
        title
        url
        width
        height
      }
    }

  }
}

`;
};

export async function getShopItem(id: string): Promise<ShopItem | undefined> {
  const data = await performContentfulQuery<{
    shopItem: ShopItem;
  }>(getShopItemQuery(id));

  return data?.shopItem;
}
