import React from "react";
import { Text, VStack } from "@chakra-ui/react";

export function BowlaniAdress() {
  return (
    <VStack alignItems="flex-start">
      <Text>BOWLANI GmbH</Text>
      <Text>
        c/o foodlab.hamburg <br />
        Überseeallee 10
        <br />
        20457 Hamburg
      </Text>
      <Text>hi@bowlani.app</Text>
    </VStack>
  );
}
