import { Text, Heading } from "@chakra-ui/react";

export function CsrHeader() {
  return (
    <Heading
      lineHeight={1.1}
      fontWeight={600}
      fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
    >
      <Text as={"span"} position={"relative"}>
        Corporate Social
      </Text>{" "}
      <Text as={"span"} color={"yellow.800"}>
        Responsibility
      </Text>
    </Heading>
  );
}
