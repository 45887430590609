import axios, { AxiosResponse } from "axios";
import { createContext, useEffect, useState } from "react";
import { useMutation, UseMutationResult } from "react-query";
import { cartItem, checkOutReponse } from "./types";

export const ShopCartContext = createContext<IShopCartContext>({
  cartItems: [],
  addItem: () => {},
  setItemQuantity: () => {},
  removeItem: () => {},
  getSubtotal: () => {
    return 0;
  },
  checkout: undefined!,
  clear: () => {},
  environment: "test",
});
export type IShopCartContext = {
  cartItems: cartItem[];
  addItem: (item: cartItem) => void;
  setItemQuantity: (priceId: string, quantity: number) => void;
  removeItem: (priceId: string) => void;
  getSubtotal: () => number;
  checkout: UseMutationResult<
    AxiosResponse<checkOutReponse, any>,
    unknown,
    cartItem[],
    unknown
  >;
  clear: () => void;
  environment: "prod" | "test";
};

export function ShopCartContextProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const [cartItems, setCartItems] = useState<cartItem[]>([]);
  let url = "";

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    url = "http://localhost:5203/shop/checkout";
  } else {
    url = "https://bwln-backend.azurewebsites.net/shop/checkout";
  }

  const checkout = useMutation({
    mutationFn: (cartItems: cartItem[]) => {
      return axios.post(url, {
        checkoutItems: cartItems.map((x) => {
          return { productId: x.priceId, quantity: x.quantity };
        }),
      });
    },
  });

  useEffect(() => {
    if (cartItems.length == 0 && localStorage.getItem("cart") != null) {
      setCartItems(JSON.parse(localStorage.getItem("cart")!));
    } else {
      localStorage.setItem("cart", JSON.stringify(cartItems));
    }
  }, [cartItems]);

  return (
    <ShopCartContext.Provider
      value={{
        cartItems: cartItems,
        addItem: (item: cartItem) => {
          setCartItems((current) => {
            if (current.filter((x) => x.priceId === item.priceId).length > 0) {
              let quantity = current.filter(
                (x) => x.priceId === item.priceId
              )[0].quantity;

              quantity = quantity + 1;

              current.filter((x) => x.priceId === item.priceId)[0].quantity =
                quantity;
            } else {
              current.push(item);
            }
            return [...current];
          });
        },
        getSubtotal: () => {
          let subTotal = 0;
          cartItems.map((item) => {
            subTotal += item.priceEuro * item.quantity;
          });
          return subTotal;
        },
        removeItem: (priceId: string) => {
          setCartItems((current) => {
            return current.filter((x) => x.priceId !== priceId);
          });
        },
        setItemQuantity: (priceId: string, quantity: number) => {
          setCartItems((current) => {
            if (current.filter((x) => x.priceId === priceId).length > 0) {
              current.filter((x) => x.priceId === priceId)[0].quantity =
                quantity;
            }
            return [...current];
          });
        },
        checkout: checkout,
        clear: () => {
          localStorage.removeItem("cart");
          setCartItems([]);
        },
        environment:
          !process.env.NODE_ENV || process.env.NODE_ENV === "development"
            ? "test"
            : "prod",
      }}
    >
      {children}
    </ShopCartContext.Provider>
  );
}
