import {
  Box,
  CloseButton,
  Flex,
  HStack,
  Link,
  Select,
  SelectProps,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { PriceTag } from "./PriceTag";
import { CartProductMeta } from "./CartProductMeta";
import { useContext } from "react";
import { ShopCartContext } from "./ShopCartContext";
import { cartItem } from "./types";

type CartItemProps = {
  isGiftWrapping?: boolean;
  name: string;
  description: string;
  quantity: number;
  price: number;
  currency: string;
  imageUrl: string;
  onChangeQuantity?: (quantity: number) => void;
  onClickGiftWrapping?: () => void;
  onClickDelete?: () => void;
};

const QuantitySelect = (props: SelectProps) => {
  return (
    <Select
      maxW="64px"
      aria-label="Select quantity"
      focusBorderColor={useColorModeValue("blue.500", "blue.200")}
      {...props}
    >
      <option value="0">0</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
    </Select>
  );
};

export function CartItem({ cartItem }: { cartItem: cartItem }) {
  const cartCtx = useContext(ShopCartContext);

  return (
    <Flex direction={{ base: "column", md: "row" }} justify="space-between">
      <Box>
        <CartProductMeta
          name={cartItem.displayName}
          description={cartItem.description}
          image={cartItem.thumbnail?.url || ""}
        />
      </Box>

      <HStack alignItems="center" spacing={4}>
        <QuantitySelect
          value={cartItem.quantity}
          onChange={(e) => {
            if (+e.currentTarget.value == 0) {
              if (cartCtx.cartItems.length == 1) {
                cartCtx.clear();
              } else {
                cartCtx.removeItem(cartItem.priceId);
              }
            } else {
              cartCtx.setItemQuantity(cartItem.priceId, +e.currentTarget.value);
            }
          }}
        />

        <PriceTag price={cartItem.priceEuro} currency={"EUR"} />
      </HStack>
    </Flex>
  );
}
