import { Ingredient, Recipe } from "../../global/types";

export function extractIngredientsFromRecipeSteps(
  recipe: Recipe
): Ingredient[] {
  let ingredientsUngrouped = recipe.stepsCollection.items
    .filter((x) => x.ingredientAlternative == undefined)
    .flatMap((ingredients) =>
      ingredients.ingredientsCollection.items.flatMap(
        (ingredient) => ingredient
      )
    );

  return ingredientsUngrouped;
}
