import { Ingredient } from "../../global/types";

export function groupIngredients(
  ingredientsUngrouped: Ingredient[]
): Ingredient[] {
  var ingredientsGrouped = [] as Ingredient[];

  ingredientsUngrouped.map((ingredient) => {
    let indexOfIngredient = ingredientsGrouped.findIndex(
      (findItem) =>
        findItem.food.sys.id == ingredient.food.sys.id &&
        findItem.unit?.toLowerCase() == ingredient.unit?.toLowerCase() &&
        findItem.foodAlternative?.sys.id ==
          ingredient.foodAlternative?.sys.id &&
        findItem.unitAlternative?.toLowerCase() ==
          ingredient.unitAlternative?.toLowerCase()
    );

    if (indexOfIngredient == -1) {
      ingredientsGrouped.push({ ...ingredient });
    } else {
      if (ingredientsGrouped[indexOfIngredient]) {
        ingredientsGrouped[indexOfIngredient].amount += ingredient.amount;

        if (ingredient.amountAlternative) {
          let currentAmount =
            ingredientsGrouped[indexOfIngredient].amountAlternative || 0;

          ingredientsGrouped[indexOfIngredient].amountAlternative =
            currentAmount + ingredient.amountAlternative;
        }
      }
    }
  });

  return ingredientsGrouped;
}
