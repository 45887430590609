export async function performContentfulQuery<T>(
  query: string
): Promise<T | undefined> {
  const response = await window.fetch(
    `https://graphql.contentful.com/content/v1/spaces/mpwsd5i0uycq/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authenticate the request
        Authorization: "Bearer 2vmATdBQ1ztCV3pOAKBvFT7kDj3jCCVDrjsFYBzmOso",
      },
      // send the GraphQL query
      body: JSON.stringify({ query }),
    }
  );

  const { data, errors } = await response.json();

  if (errors) {
    console.error(errors);
    return undefined;
  }

  return data as T;
}
