import React from "react";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { Box, Image } from "@chakra-ui/react";
import "./recipeDetailsCarousel.css";

export function RecipeDetailsCarousel({
  heroImgUrls,
}: {
  heroImgUrls: string[] | undefined;
}) {
  return (
    <AwesomeSlider bullets={true} className="aws-btn aws-higher">
      {heroImgUrls?.map((heroImgUrl, i) => (
        <div data-src={heroImgUrl} />
      ))}
    </AwesomeSlider>
  );
}
