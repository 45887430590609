import { Text } from "@chakra-ui/react";

export function CsrSaveTheChIntroText() {
  return (
    <Text fontSize={"larger"}>
      Wir unterstützen die Arbeit von{" "}
      <strong>Save the Children Deutschland e.V.</strong> in Afghanistan mit dem
      Schwerpunkt auf die Bekämpfung von Mangelernährung bei Kindern. Hierfür
      spenden wir einen festen Teil unserer Einnahmen an Save the Children
      Deutschland e.V. Unter dem Nachfolgenden Link erfährst Du mehr über die
      Arbeit vor Ort.
    </Text>
  );
}
