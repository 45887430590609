import React from "react";
import { Box, Divider, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { Heading, VStack } from "@chakra-ui/react";
import { HeadingMedium } from "../../generic/headingMedium";
import {
  BsArrowLeftRight,
  BsBasket,
  BsBasketFill,
  BsCheck2Circle,
  BsReceiptCutoff,
} from "react-icons/bs";
import { CalculatedIngredient } from "./IngredientOverviewItem";
import { Recipe, Step } from "../../global/types";

export function StepTextsOverview({
  recipe,
  portions,
}: {
  recipe: Recipe;
  portions: number;
}) {
  return (
    <VStack>
      <HeadingMedium>Zubereitung</HeadingMedium>
      <VStack spacing={8}>
        {recipe.stepsCollection.items?.map((step, i) => (
          <VStack
            key={i}
            alignSelf="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            <Box>
              <Title step={step} stepNumber={i} />

              {step.ingredientsCollection.items.length > 0 && (
                <Ingredients recipe={recipe} step={step} portions={portions} />
              )}
            </Box>

            <StepInstructions step={step} />
          </VStack>
        ))}
      </VStack>
    </VStack>
  );
}

function StepInstructions({ step }: { step: Step }) {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: { content: any }, next: (arg0: any) => any) =>
        `<p>${next(node.content)}</p>`,
    },
  };

  return <Box>{step.stepInstructionsPlainText}</Box>;
}

function Title({ stepNumber, step }: { stepNumber: number; step: Step }) {
  return (
    <Box>
      <Box
        style={{ display: "inline" }}
        rounded={3}
        p={1}
        bg={"teal.400"}
        color="blackAlpha.800"
        textColor="teal.1000"
      >
        {stepNumber + 1}
        {step.stepTitleApp && <>: {step.stepTitleApp}</>}
      </Box>
    </Box>
  );
}

function Ingredients({
  step,
  portions,
  recipe,
}: {
  step: Step;
  portions: number;
  recipe: Recipe;
}) {
  return (
    <HStack
      ml="1.5"
      rounded="3"
      p="2"
      borderLeft="4px"
      borderLeftColor="teal.400"
    >
      <Box color="teal.800">
        {step.ingredientAlternative && (
          <>
            <CalculatedIngredient
              key={"alt"}
              ingredient={step.ingredientAlternative}
              portions={portions}
              defaultPortions={recipe.portions}
            />
            {/*  <DividerWithIcon />*/}
          </>
        )}
        {step.ingredientAlternative ? (
          <IngredientAlternative>
            <IngredientLister
              step={step}
              defaultPortions={recipe.portions}
              portions={portions}
            />
          </IngredientAlternative>
        ) : (
          <IngredientLister
            step={step}
            defaultPortions={recipe.portions}
            portions={portions}
          />
        )}
      </Box>
    </HStack>
  );
}

function IngredientLister({
  step,
  defaultPortions,
  portions,
}: {
  step: Step;
  defaultPortions: number;
  portions: number;
}) {
  return (
    <>
      {step.ingredientsCollection.items.map((ingredient, i) => (
        <span>
          <CalculatedIngredient
            key={i}
            ingredient={ingredient}
            portions={portions}
            defaultPortions={defaultPortions}
          />
          <div style={{ display: "inline" }}>
            {i + 1 < step.ingredientsCollection.items.length ? ", " : " "}
          </div>
        </span>
      ))}
    </>
  );
}

function IngredientAlternative({ children }: { children: JSX.Element }) {
  return (
    <Box mt="2" rounded="3" p="2" bg="gray.50">
      <HStack>
        <BsArrowLeftRight
          color="blackAlpha.600"
          style={{
            display: "inline",
            width: 12,
            height: 12,
          }}
        />
        <Text>alternativ:</Text>
      </HStack>
      {children}
    </Box>
  );
}

function DividerWithIcon() {
  return (
    <HStack my="1">
      <Divider orientation="horizontal" borderColor="teal.600" width="10" />
      <Box color="teal.600">
        <HStack px="1" borderColor="teal.400" borderWidth="1px">
          <BsArrowLeftRight
            color="blackAlpha.600"
            style={{
              display: "inline",
              width: 12,
              height: 12,
            }}
          />
          <Text fontSize="sm">alternativ</Text>
        </HStack>
      </Box>
      <Divider orientation="horizontal" borderColor="teal.600" />
    </HStack>
  );
}
