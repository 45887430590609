export const bowlaniColors = {
  teal: {
    200: "#d1e2df",
    400: "#b4d0cb",
    600: "#82b0a8",
    800: "#518178",
    1000: "#273f3a",
  },
  blue: {
    200: "#c8d6e1",
    400: "#b2d1eb",
    600: "#7ab0dc",
    800: "#51799b",
    1000: "#24455d",
  },
  gray: {
    200: "#d6d6d6",
    400: "#c2c2c2",
    600: "#999999",
    800: "#656566",
    1000: "#1d1d1b",
  },
  yellow: {
    200: "#f8e2c3",
    400: "#f2ca91",
    600: "#eeb864",
    800: "#d19630",
    1000: "#765718",
  },
  red: {
    200: "#f5e1e0",
    400: "#e8b7b5",
    600: "#d8827f",
    800: "#ba5854",
    1000: "#69322f",
  },
};
