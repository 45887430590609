import { Recipe } from "../types";
import { performContentfulQuery } from "./performContentfulQuery";

const foodQuery = `
sys {
  id
}
title
titlePlural
showInIngredientOverviewList
picture {
  url
}
`;

const ingredientQuery = `
sys {
  id
}
unitHasSpace
unit
amount
foodAlternative {
  ${foodQuery}
}
unitAlternativeHasSpace
unitAlternative
amountAlternative
food {
  ${foodQuery}
}
optional
`;

const getRecipeQuery = (recipeId: string) => {
  return `
{
  recipe(id: "${recipeId}") {
    sys {
      id
    }
    shortTitle
    nativeTitle
    longTitle
    picturesCollection {
      items {
        title
        url
        width
        height
      }
    }
    tip {
      json
    }
    category {
      titleShort
      sys {
        id
      }
    }
    cookingTime
    preparationTime
    bakingTime
    calories
    isVegan
    isVegetarian
    introduction
    portions
    pieces
    stepsCollection(limit: 20) {
      items {
        sys {
          id
        }
        ingredientsCollection(limit: 20) {
          items {
            ${ingredientQuery}
          }
        }
        stepTitleApp
        stepTitleInternal
        stepInstructions {
          json
        }
        stepInstructionsPlainText
        ingredientAlternative {
          ${ingredientQuery}
        }
      }
    }
  }
}

`;
};

export async function getRecipe(id: string): Promise<Recipe | undefined> {
  const data = await performContentfulQuery<{
    recipe: Recipe;
  }>(getRecipeQuery(id));

  return data?.recipe;
}
