import React, { useContext } from "react";
import { Box, Button, Icon } from "@chakra-ui/react";
import { ShopItem } from "./types";
import { ShopCartContext } from "./ShopCartContext";
import { FiShoppingCart } from "react-icons/fi";

export function AddToCart({
  shopItem,
  variant,
}: {
  shopItem: ShopItem;
  variant: "regular" | "small";
}) {
  const cartCtx = useContext(ShopCartContext);
  return (
    <Button
      onClick={() =>
        cartCtx.addItem({
          priceId:
            cartCtx.environment == "prod"
              ? shopItem.stripePriceId
              : shopItem.stripePriceIdTest,
          quantity: 1,
          displayName: shopItem.displayName,
          thumbnail: shopItem.thumbnail,
          priceEuro: shopItem.priceEuro,
          description: shopItem.subtitle,
        })
      }
      leftIcon={<Icon as={FiShoppingCart} />}
    >
      {variant == "regular" && (
        <Box display={{ base: "none", sm: "block" }}>In den Warenkorb</Box>
      )}
    </Button>
  );
}
