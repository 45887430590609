import ReactGA from "react-ga";

export function googleAnalytics() {
  if (localStorage.getItem("useAnalytics") == "1") {
    ReactGA.initialize("UA-197049944-2");

    //www.bowlani.app: G-7K8GPNGC7W
    // legacy: G-B2BEFGXFNV
    //unicorn: G-4PJ1GFQW4V
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}
