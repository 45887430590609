import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiClock } from "react-icons/fi";
import {
  BsPeople,
  BsCardList,
  BsClock,
  BsClockHistory,
  BsClockFill,
} from "react-icons/bs";
import { RiLeafFill, RiLeafLine } from "react-icons/ri";

export function RecipeBadge({
  icon,
  text,
}: {
  icon: "person" | "prep" | "clock" | "leaf-full" | "leaf" | "category";
  text: string;
}) {
  return (
    <Box rounded={3} p={2} bg={icon == "category" ? "teal.400" : "teal.400"}>
      <HStack space={2} color="teal.1000">
        {icon == "prep" && <BsClockHistory size={16} />}
        {icon == "clock" && <BsClockFill size={16} />}
        {icon == "person" && <BsPeople size={16} />}
        {icon == "leaf" && <RiLeafLine size={16} />}
        {icon == "leaf-full" && <RiLeafFill size={16} />}

        {icon == "category" && <BsCardList size={16} />}

        <Text>{text}</Text>
      </HStack>
    </Box>
  );
}
