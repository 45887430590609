import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Image,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { FcAssistant, FcDonate, FcInTransit } from "react-icons/fc";
import { Container } from "../../generic/layout/container";
import pic1 from "./previewPictures/1.jpg";
import pic2 from "./previewPictures/2.jpg";

import AppStoreBadge from "../../generic/storeBadges/appStoreBadge.png";
import GooglePlayBadge from "../../generic/storeBadges/googlePlayBadge.png";

import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { bowlaniColors } from "../../global/bowlaniColors";
import { AppPictures } from "./appPictures";

interface PreviewPicProps {
  title: string;
  img: string;
}

const PreviewPic = ({ title, img }: PreviewPicProps) => {
  return (
    <Image
      src={img}
      boxSize="md"
      fit={"contain"}
      align={"center"}
      borderRadius="md"
      height="378px"
    />
  );
};

export function PreviewPicturesComponent() {
  return (
    <MaxWidthContainer>
      <Stack flex={1} spacing={{ base: 5, md: 5 }}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
        >
          <Text as={"span"} position={"relative"}>
            Bereit für das
          </Text>{" "}
          <Text as={"span"} color={"teal.800"}>
            BOWLANI Erlebnis?
          </Text>
        </Heading>

        <Text color={bowlaniColors.gray[800]} fontSize={"larger"}>
          Lade jetzt die BOWLANI App runter und entdecke unsere Originalrezepte:
        </Text>

        {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={3}>
          <PreviewPic title={""} img={pic1} />
          <PreviewPic title={""} img={pic2} />
        </SimpleGrid> */}
      </Stack>
      <AppPictures />
      <VStack spacing={4}>
        <Stack spacing={2} direction={{ base: "row" }}>
          <a href="https://apple.co/3zRKvlH">
            <Image
              src={AppStoreBadge}
              fit="cover"
              maxW={["32", "48"]}
              blur="2px"
            />
          </a>

          <a href="https://play.google.com/store/apps/details?id=app.bowlani&gl=DE">
            <Image
              src={GooglePlayBadge}
              fit="cover"
              maxW={["32", "48"]}
              blur="2px"
            />
          </a>
        </Stack>
      </VStack>
    </MaxWidthContainer>
  );
}
