import React from "react";
import { Box, Flex, Heading, HStack } from "@chakra-ui/react";
import { Container } from "./container";
import favIcon from "../../img/Logo-Favicon.png";
import logoTyping from "./BOWLANI-app-Logo-with-white-O-bg.svg";
import bowlaniLogo from "./BOWLANI-Logo-with-claim.svg";
import logoPng from "./BOWLANI-Logos-with-white-O-bg.png";
import { Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { MaxWidthContainer } from "./maxWidthContainer";
import { BowlaniLogo } from "../BowlaniLogo";

export function Header() {
  return (
    <MaxWidthContainer style={{ marginBottom: 40, marginTop: 40 }}>
      <Link to={"/"} style={{ display: "inline-block" }}>
        {/* <BowlaniLogo colorSchema="regular" w="300" /> */}
        <Image
          src={logoTyping}
          fit="scale-down"
          style={{ display: "inline-block", height: 44 }}
        />
      </Link>
    </MaxWidthContainer>
  );
}
