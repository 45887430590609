import { Routes, Route } from "react-router";

import { ShopItemDetails } from "./ShopItemDetails";
import { ShopCartContextProvider } from "./ShopCartContext";
import { ShopLandingPage } from "./ShopLandingPage";
import { Cart } from "./Cart";
import { Checkout } from "./Checkout";

export function Shop() {
  return (
    <ShopCartContextProvider>
      <Routes>
        <Route index element={<ShopLandingPage />} />
        <Route path="item/:shopItemId" element={<ShopItemDetails />} />
        <Route path="cart" element={<Cart />} />
        <Route path="checkout" element={<Checkout />} />
      </Routes>
    </ShopCartContextProvider>
  );
}
