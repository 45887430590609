import { Divider, Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";
import { CsrHeader } from "./components/CsrHeader";
import { CsrSaveTheChIntroText } from "./components/CsrSaveTheChIntroText";
import { CsrZeroHungerBanner } from "./components/CsrZeroHungerBanner";

export function CSR() {
  return (
    <ContentContainer header={<CsrHeader />}>
      <VStack spacing={5}>
        <CsrZeroHungerBanner />

        <Text fontSize={"larger"} style={{ fontWeight: 800 }}>
          Bericht von Save the Children Deutschland e.V. über die Lage vor Ort
          in Afghanistan mit dem Schwerpunkt der Mangelernährung:
        </Text>
        <Text fontSize={"larger"}>
          Auch über ein Jahr nach der Machtübernahme der Taliban im August 2021
          ist die Situation in Afghanistan verheerend. In Folge der Klima- und
          Wirtschaftskrise hat sich diese Situation verschlimmert. Millionen von
          Menschen leiden in Afghanistan Hunger.
        </Text>
        <Text fontSize={"larger"}>
          Besonders schlimme Konsequenzen hat das für Kinder, die sich noch im
          Wachstum befinden und deren Immunsystem noch nicht vollständig
          entwickelt ist.
        </Text>
        <Text fontSize={"larger"}>
          Deswegen kümmern sich mobile Gesundheits- und Ernährungsteams von Save
          the Children besonders intensiv um Kinder unter fünf Jahren, die an
          akuter Mangelernährung leiden.
        </Text>
        <Text fontSize={"larger"}>
          Aktuell, im November 2022, sind insgesamt 66 solcher Teams der
          Organisation in ganz Afghanistan unterwegs. Sie kümmern sich zum
          Beispiel um Familien wie die von Sonia (Name zum Schutz geändert), die
          in der Provinz Faryab im Norden Afghanistans lebt.{" "}
          <span style={{ fontStyle: "italic" }}>
            „Unsere Kinder sind schwach; wir haben nichts zu essen oder zu
            trinken. Auf den Feldern ist kein einziges Pfund Weizen mehr. Unsere
            Kinder sind krank und wir können uns die Medizin nicht leisten.“
          </span>{" "}
          erzählt sie dem Save the Children Team vor Ort.
        </Text>
        <Text fontSize={"larger"}>
          Sonias Kinder wurden deswegen mit therapeutischer Spezialnahrung
          behandelt. Das Team von Save the Children hat sie mit einem Vorrat
          hochkalorischer Erdnusspaste versorgt und kommt nun regelmäßig, um die
          Kinder zu untersuchen. Wenn sich der Zustand durch die Behandlung vor
          Ort nicht merklich verbessert, werden die Kinder an ein Krankenhaus
          überwiesen und die Eltern bei den Kosten und der Fahrt unterstützt.
        </Text>
        <Text fontSize={"larger"}>
          Save the Children leistet schon seit 1976 Hilfseinsätze in
          Afghanistan. Die Organisation arbeitet dort in den Bereichen
          Gesundheit, Ernährung, Existenzsicherung, Bildung und Kinderschutz.
        </Text>
        <Text fontSize={"larger"} style={{ fontWeight: 800 }}>
          Wir bei BOWLANI möchten diese Arbeit unterstützen und werden einen
          festen Teil unserer Einnahmen an Save the Children Deutschland e.V.
          spenden.
        </Text>
      </VStack>
    </ContentContainer>
  );
}
