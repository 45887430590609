import React from "react";
import { VStack } from "@chakra-ui/react";
import { Layout } from "./generic/layout/layout";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { LandingPage } from "./pages/landing";
import { EmailValidatedPage } from "./pages/email-validated";
import { ImprintComponent } from "./pages/imprint";
import { DataPrivacyComponent } from "./pages/data-privacy";
import { TermsAndConditionsComponent } from "./pages/terms";
import { Helmet } from "react-helmet";
import { CookieConsentComponent } from "./generic/cookieConsent";

import { theme } from "./generic/chakraUiTheme";
import { googleAnalytics } from "./generic/googleAnalytics";
import ScrollToTop from "./generic/scrollToTop";
import { RecipeComponent } from "./pages/recipe";
import { AboutUs } from "./pages/about-us";
import { CSR } from "./pages/csr";
import { Download } from "./pages/download";
import { Shop } from "./pages/shop";

googleAnalytics();

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Helmet>
          <title>BOWLANI APP - Aus Liebe zum Guten Essen</title>
          <meta name="description" content="Aus Liebe zum Guten Essen" />
        </Helmet>

        <CookieConsentComponent />

        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route index element={<LandingPage />} />
              <Route
                path="/validate-email/:validationId"
                element={<EmailValidatedPage />}
              />
              <Route path="/imprint" element={<ImprintComponent />} />
              <Route path="/shop/*" element={<Shop />} />
              <Route path="/recipe/:recipeId" element={<RecipeComponent />} />
              <Route path="/data-privacy" element={<DataPrivacyComponent />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/csr" element={<CSR />} />
              <Route path="/download" element={<Download />} />
              {/* <Route path="/terms">
                <TermsAndConditionsComponent />
              </Route> */}
            </Routes>
          </Layout>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;
