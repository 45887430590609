import React from "react";
import { Box, Heading, HStack, VStack, Text } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";

import { BsArrowLeftRight } from "react-icons/bs";
import { Ingredient, Food } from "../../global/types";

export function IngredientOverviewItem({
  ingredient,
  portions,
  defaultPortions,
}: {
  ingredient: Ingredient;
  portions: number;
  defaultPortions: number;
}) {
  //show space between amount and unit if unit starts with a capital letter
  // g => no space
  // TL => space
  let unitSpace =
    ingredient.unit?.substring(0, 1) ==
    ingredient.unit?.substring(0, 1).toLocaleUpperCase()
      ? " "
      : "";

  // 2 portions/persons is default amount for recipes
  return (
    <Box>
      <HStack justifyContent="flex-start" alignItems="flex-start">
        {/* <Image
          size={10}
          resizeMode={"cover"}
          borderRadius={100}
          src={ingredient.food.picture.url + "?w=40"}
          alt="Alternate Text"
        /> */}
        <HStack spacing={1} alignItems="flex-start">
          <CalculatedIngredient
            ingredient={ingredient}
            portions={portions}
            defaultPortions={defaultPortions}
          />
        </HStack>
      </HStack>
    </Box>
  );
}

export function CalculatedIngredient({
  ingredient,
  portions,
  defaultPortions,
  suffix,
}: {
  ingredient: Ingredient;
  portions: number;
  defaultPortions: number;
  suffix?: string | undefined;
}): JSX.Element {
  let calculatedAmount = (ingredient.amount / defaultPortions) * portions;
  let calculatedAmountAlternative =
    ingredient.foodAlternative && ingredient.amountAlternative
      ? (ingredient.amountAlternative / defaultPortions) * portions
      : 0;

  return (
    <div style={{ display: "inline" }}>
      {amountParser(calculatedAmount)}
      {ingredient.unit && <> {ingredient.unit}</>}{" "}
      {foodTitleParser(ingredient.food, calculatedAmount)}
      {ingredient.foodAlternative && (
        <>
          {" "}
          (
          <BsArrowLeftRight
            style={{
              display: "inline",
              width: 12,
              height: 12,
              marginBottom: 2,
            }}
          />{" "}
          alternativ: {amountParser(calculatedAmountAlternative)}
          {ingredient.unitAlternative && (
            <> {ingredient.unitAlternative}</>
          )}{" "}
          {foodTitleParser(
            ingredient.foodAlternative,
            calculatedAmountAlternative
          )}
          ){suffix}
        </>
      )}
    </div>
  );
}

export function foodTitleParser(food: Food, amount: number): string {
  if (amount >= 2) {
    return food.titlePlural ? food.titlePlural : food.title;
  } else {
    return food.title;
  }
}

export function amountParser(amount: number): string {
  switch (amount) {
    case 0.75:
      return "¾";
    case 0.5:
      return "½";
    case 0.25:
      return "¼";
    default:
      return amount.toString();
  }
}
