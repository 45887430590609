import { Stack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Container } from "../../generic/layout/container";
import { ContentContainer } from "../../generic/layout/contentContainer";
import { BowlaniAdress } from "../../helper/BowlaniAdress";

export function DataPrivacyComponent() {
  return (
    <ContentContainer header={<>Datenschutzerklärung</>}>
      <>
        <h2>
          Ein verantwortungsbewusster Umgang mit personenbezogenen Daten hat bei
          BOWLANI hohe Priorit&auml;t.{" "}
        </h2>
        <p>
          Wir m&ouml;chten, dass Nutzerinnen und Nutzer wissen, wann welche
          Daten durch BOWLANI erhoben und verwendet werden. Personenbezogene
          Daten werden von uns nur im notwendigen Umfang verarbeitet. Welche
          Daten zu welchem Zweck und auf welcher Grundlage ben&ouml;tigt und
          verarbeitet werden, richtet sich ma&szlig;geblich nach der Art der
          Leistung, die von Ihnen in Anspruch genommen wird, beziehungsweise ist
          abh&auml;ngig davon, f&uuml;r welchen Zweck diese ben&ouml;tigt
          werden. Wir haben technische und organisatorische Ma&szlig;nahmen
          getroffen, die sicherstellen, dass die Vorschriften &uuml;ber den
          Datenschutz sowohl von uns als auch von unseren externen
          Dienstleistern beachtet werden. Die Verarbeitung personenbezogener
          Daten erfolgt in &Uuml;bereinstimmung mit der&nbsp;
          <a
            title="&Ouml;ffnet ein neues Fenster."
            href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32016R0679&amp;qid=1527147390147&amp;from=EN"
            target="_blank"
          >
            Europ&auml;ischen Datenschutz-Grundverordnung (DSGVO)
          </a>
          &nbsp;und dem&nbsp;
          <a
            title="&Ouml;ffnet ein neues Fenster."
            href="http://www.gesetze-im-internet.de/bdsg_2018/"
            target="_blank"
          >
            Bundesdatenschutzgesetz (BDSG)
          </a>
          .
        </p>

        <strong>1. Name und Anschrift der Verantwortlichen</strong>

        <p>
          Verantwortlich im Sinne der DSGVO und nationaler Datenschutzgesetze
          der Mitgliedstaaten sowie sonstiger datenschutzrechtlicher
          Bestimmungen ist die
        </p>

        <p>
          <BowlaniAdress />
        </p>

        <strong>2. Beschreibung und Umfang der Datenverarbeitung</strong>

        <p>
          Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt nur
          nach Einwilligung des Nutzers. Gem&auml;&szlig; Art. 6 Abs. 1 S. 1
          lit. a) DSGVO sind wir zur Erhebung, Speicherung und &Uuml;bermittlung
          personenbezogener Daten berechtigt, wenn der Betroffene in die
          Datenverarbeitung eingewilligt hat. Eine Ausnahme gilt f&uuml;r die
          F&auml;lle, in denen eine vorherige Einholung einer Einwilligung aus
          tats&auml;chlichen Gr&uuml;nden nicht m&ouml;glich ist und die
          Verarbeitung der Daten durch gesetzliche Vorschriften erlaubt ist.
          Eine Weitergabe Ihrer personenbezogenen Daten erfolgt
          ausschlie&szlig;lich innerhalb der einschl&auml;gigen, insbesondere
          datenschutz- und wettbewerbsrechtlichen Vorgaben.
        </p>

        <strong>3. Ihre Betroffenenrechte</strong>

        <p>
          Unter den angegebenen Kontaktdaten k&ouml;nnen Sie jederzeit die
          nachfolgenden Rechte aus&uuml;ben:
        </p>

        <ul style={{ marginLeft: 30 }}>
          <li>
            Auskunft &uuml;ber Ihre bei uns gespeicherten Daten und deren
            Verarbeitung (Art. 15 DSGVO),
          </li>
          <li>
            Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
          </li>
          <li>
            L&ouml;schung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),
          </li>
          <li>
            Einschr&auml;nkung der Datenverarbeitung, sofern wir Ihre Daten
            aufgrund gesetzlicher Pflichten noch nicht l&ouml;schen d&uuml;rfen
            (Art. 18 DSGVO),
          </li>
          <li>
            Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21
            DSGVO) und
          </li>
          <li>
            Daten&uuml;bertragbarkeit, sofern Sie in die Datenverarbeitung
            eingewilligt haben oder einen Vertrag mit uns abgeschlossen haben
            (Art. 20 DSGVO).
          </li>
        </ul>

        <p>
          Sofern Sie uns eine Einwilligung erteilt haben, k&ouml;nnen Sie diese
          jederzeit mit Wirkung f&uuml;r die Zukunft widerrufen.
        </p>

        <strong>
          4. Erfassung allgemeiner Informationen beim Besuch unserer Website
        </strong>

        <strong>a. Datenverarbeitung</strong>

        <p>
          Bei jedem Aufruf unserer Internetseite erfasst unser System
          automatisiert Daten und Informationen vom zugreifenden Ger&auml;t
          (sog. &bdquo;Logfiles&ldquo;). Zu diesen Informationen geh&ouml;ren
          unter anderem Datum und Uhrzeit des Abrufs (Zeitstempel), sowie die
          IP-Adresse des Ger&auml;ts, Art des Webbrowsers, das verwendete
          Betriebssystem und den Domainnamen Ihres Internet-Service-Providers.
          Diese Daten werden insbesondere zu folgenden Zwecken verarbeitet:
        </p>

        <ul style={{ marginLeft: 30 }}>
          <li>Sicherstellung eines problemlosen Verbindungsaufbaus,</li>
          <li>Sicherstellung einer reibungslosen Nutzung,</li>
          <li>Auswertung der Systemsicherheit und -stabilit&auml;t sowie</li>
          <li>Optimierung unserer Website.</li>
        </ul>

        <p>
          Wir verwenden Ihre Daten nicht, um R&uuml;ckschl&uuml;sse auf Ihre
          Person zu ziehen. Informationen dieser Art werden von uns ggfs.
          anonymisiert statistisch ausgewertet, um unseren Internetauftritt und
          die dahinterstehende Technik zu optimieren.
        </p>

        <strong>b. Rechtsgrundlage und berechtigtes Interesse</strong>

        <p>
          Die Verarbeitung erfolgt gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f)
          der DSGVO auf Basis unseres berechtigten Interesses an der
          Verbesserung der Stabilit&auml;t und Funktionalit&auml;t unserer
          Website.
        </p>

        <strong>c. Empf&auml;nger</strong>

        <p>
          Empf&auml;nger der Daten sind ggf. technische Dienstleister, die
          f&uuml;r den Betrieb und die Wartung unserer Webseite als
          Auftragsverarbeiter t&auml;tig werden.
        </p>

        <p>
          Die personenbezogenen Daten der betroffenen Person werden
          gel&ouml;scht oder gesperrt, sobald der Zweck der Speicherung
          entf&auml;llt. Eine Speicherung kann dar&uuml;ber hinaus erfolgen,
          wenn dies durch den europ&auml;ischen oder nationalen Gesetzgeber in
          unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften,
          denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung
          oder L&ouml;schung der Daten erfolgt auch dann, wenn eine durch die
          genannten Normen vorgeschriebene Speicherfrist abl&auml;uft, es sei
          denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten
          f&uuml;r einen Vertragsabschluss oder eine Vertragserf&uuml;llung
          besteht. F&uuml;r die Daten, die der Bereitstellung der Website
          dienen, ist dies grunds&auml;tzlich der Fall, wenn die jeweilige
          Sitzung beendet ist.
        </p>

        <strong>e. Bereitstellung vorgeschrieben oder erforderlich</strong>

        <p>
          Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
          gesetzlich noch vertraglich vorgeschrieben. Ohne die IP-Adresse ist
          jedoch der Dienst und die Funktionsf&auml;higkeit unserer Website
          nicht gew&auml;hrleistet. Zudem k&ouml;nnen einzelne Dienste und
          Services nicht verf&uuml;gbar oder eingeschr&auml;nkt sein. Aus diesem
          Grund ist ein Widerspruch ausgeschlossen.
        </p>

        <strong>f. Widerruf der Einwilligung</strong>

        <p>
          Vom Anbieter wird derzeit keine M&ouml;glichkeit f&uuml;r einen
          einfachen Opt-out oder ein Blockieren der Daten&uuml;bertragung
          angeboten. Wenn Sie eine Nachverfolgung Ihrer Aktivit&auml;ten auf
          unserer Website verhindern wollen, widerrufen Sie bitte im
          Cookie-Consent-Tool Ihre Einwilligung f&uuml;r die technisch nicht
          notwendigen Cookies und Daten&uuml;bertragungen. In diesem Fall
          k&ouml;nnen Sie unsere Website jedoch nicht oder nur
          eingeschr&auml;nkt nutzen.
        </p>

        <strong>
          5. Information &uuml;ber Ihr Widerspruchsrecht nach Art. 21 DSGVO
        </strong>

        <p>
          Sie haben das Recht jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund Art. 6 Abs. 1 S. 1 lit. f) DSGVO
          (Datenverarbeitung auf der Grundlage einer Interessenabw&auml;gung)
          erfolgt, Widerspruch einzulegen; dies gilt auch f&uuml;r ein auf diese
          Bestimmung gest&uuml;tztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
        </p>

        <p>
          Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten
          nicht mehr verarbeiten, es sei denn, wir k&ouml;nnen zwingende
          schutzw&uuml;rdige Gr&uuml;nde f&uuml;r die Verarbeitung nachweisen,
          die Ihre Interessen, Rechte und Freiheiten &uuml;berwiegen, oder die
          Verarbeitung dient der Geltendmachung, Aus&uuml;bung oder Verteidigung
          von Rechtsanspr&uuml;chen. Bitte richten Sie Ihren Widerspruch an:
        </p>

        <p>
          Dr. W. Mangal
          <br />
          <BowlaniAdress />
        </p>
      </>
    </ContentContainer>
  );
}
