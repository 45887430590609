import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { bowlaniColors } from "../../global/bowlaniColors";

import spicesHero from "./heroPictures/spices.jpg";

export function ShopLink() {
  return (
    <Box width="100%">
      <Image
        alt={"BOWLANI Shop"}
        fit={"contain"}
        align={"center"}
        src={spicesHero}
        w={"100%"}
        ml="auto"
        mr="auto"
      />
      <Box bg={bowlaniColors.teal[200]} width="100%">
        <MaxWidthContainer>
          <Box py={12}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              pb={{ base: "8", sm: "4" }}
              fontSize={{ base: "2xl", sm: "3xl", lg: "4xl" }}
            >
              <Text as={"span"} position={"relative"}>
                Gute
                <Text as={"span"} color={"teal.800"}>
                  {" "}
                  Zutaten{" "}
                </Text>
                für gutes Essen
              </Text>
            </Heading>
            <VStack spacing={8} align={"center"}>
              <Text fontSize={"larger"}>
                Als Ergänzung zu den Rezepten aus der App findest Du in unserem
                Online Shop die passenden Gewürze zum optimalen Nachkochen:{" "}
                <br />
              </Text>
              <a href="https://www.bowlani.shop" target="_blank">
                <Button colorScheme="teal">
                  <Text color={bowlaniColors.gray[800]}>www.bowlani.shop</Text>
                </Button>
              </a>
            </VStack>
          </Box>
        </MaxWidthContainer>
      </Box>
    </Box>
  );
}
